<template lang="pug">
.container.container-homepage.mt-4
  content-renderer(:renderData="content")
</template>

<script>
import ContentRenderer from "./components/DynamicContent/DynamicContentRenderer.vue";
import resDefs from "../../../portal/pages/admin/definitions";

export default {
  name: "DynamicPage",
  components: {
    ContentRenderer,
  },
  data() {
    const resName = this.$route.params.resource;
    return {
      resName,
      content: [],
      contentLoaded: false,
    };
  },
  async mounted() {
    const result = await this.$apollo.query({
      query: resDefs.strapiContents.filteredListQuery,
      variables: {
        filter: {
          collectionType: "DynamicPage",
          slugs: [this.resName],
        },
      },
    });
    result?.data?.strapiContents?.edges.forEach((content) => {
      this.content = content.node?.dynamicContent;
    });
    if (this.content.length > 0) {
      this.contentLoaded = true;
    } else {
      this.$router.push("/error/not-found");
    }
  },
};
</script>
